import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import {
  getLoadAccountError,
  getLoadSocialSettingsError,
} from '../../selectors/errors';

import { actionLoadAccountRequest } from '../../actions/loadAccount';
import { actionLoadSocialSettingsRequest } from '../../actions/loadSocialSettings';


export default ({ getState, dispatch }) => (
  function loadModelAccountHandler(nextState, successCallback, errorCallback) {
    Promise.all([
      dispatch(actionLoadAccountRequest()),
      dispatch(actionLoadSocialSettingsRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadAccountError(getState()),
          getLoadSocialSettingsError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            console.consoleError('loadModelAccountHandler error', globalError);
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadModelAccountHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
